@import "var";

@mixin media($width) {
  @media(min-width: $width) {
    @content;
  }
}

@mixin bp($name) {
  $width: map-get($breakpoints, $name);
  @include media($width) {
    @content;
  }
}

@mixin afterRightCentered() {
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    @content;
  }
}

@mixin flexRow($alignItems: center, $justifyContent: center) {
  display: flex;
  flex-direction: row;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin flexColumn($alignItems: center, $justifyContent: center) {
  display: flex;
  flex-direction: column;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin box($width: 100%, $height: auto) {
  width: $width;
  height: $height;
}

@mixin square($size) {
  width: $size;
  height: $size;
}

@mixin minBox($minWidth: unset, $minHeight: unset) {
  min-width: $minWidth;
  min-height: $minHeight;
}

@mixin maxBox($maxWidth: unset, $maxHeight: unset) {
  max-width: $maxWidth;
  max-height: $maxHeight;
}

@mixin font($size, $height, $weight) {
  font-weight: $weight;
  font-size: $size;
  line-height: $height;
}

@mixin fontHeight($size, $height) {
  font-size: $size;
  line-height: $height;
}

@mixin fontSetting($size, $height, $weight, $color) {
  font-size: $size;
  line-height: $height;
  font-weight: $weight;
  color: $color;
}

@mixin font-face-without-woff2($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
    url("#{$file-path}.woff") format("woff"),
    url("#{$file-path}.ttf") format("truetype");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
    url("#{$file-path}.woff") format("woff"),
    url("#{$file-path}.woff2") format("woff2"),
    url("#{$file-path}.ttf") format("truetype");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin font-face-with-svg($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.eot");
    src: url("#{$file-path}.eot?#iefix") format("embedded-opentype"),
    url("#{$file-path}.woff") format("woff"),
    url("#{$file-path}.woff2") format("woff2"),
    url("#{$file-path}.ttf") format("truetype"),
    url("#{$file-path}.svg") format("svg");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin font-face-woff($font-family, $file-path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url("#{$file-path}.woff") format("woff");
    font-weight: $weight;
    font-style: $style;
    font-display: swap;
  }
}

@mixin color($bgc: #fff, $color: #000) {
  background-color: $bgc;
  color: $color;
}