@import "base/mixins";

//тот шрифт что купили -------------------------------------------------------------------------------------------------
@include font-face('TT Norms Pro', '../font/ttnormspro/TT_Norms_Pro_Regular', 400);
@include font-face('TT Norms Pro', '../font/ttnormspro/TT_Norms_Pro_Medium', 500);
@include font-face('TT Norms Pro', '../font/ttnormspro/TT_Norms_Pro_Bold', 600);

//из старой темі с завитушками -----------------------------------------------------------------------------------------
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-thin', 100);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-thinitalic', 100, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-extralight', 200);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-extralightitalic', 200, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-light', 300);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-lightitalic', 300, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-regular', 400);
////@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-regularitalic', 400, italic); // такого файла нет
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-medium', 500);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-mediumitalic', 500, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-bold', 600);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-bolditalic', 600, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-extrabold', 700);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-extrabolditalic', 700, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-black', 800);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-blackitalic', 800, italic);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-heavy', 900);
//@include font-face-with-svg('TTNorms', '../font/tt-norms/tt-norms-heavyitalic', 900, italic);

//который сбрасывал в чат дизайнер -------------------------------------------------------------------------------------
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Thin', 100);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-ThinItalic', 100, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-ExtraLight', 200);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-ExtraLightItalic', 200, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Light', 300);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-LightItalic', 300, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Regular', 400);
////@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-RegularItalic', 400, italic);// такого файла нет
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Medium', 500);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-MediumItalic', 500, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Bold', 600);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-BoldItalic', 600, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-ExtraBold', 700);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-ExtraBoldItalic', 700, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Black', 800);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-BlackItalic', 800, italic);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-Heavy', 900);
//@include font-face-without-woff2('TTNorms', '../font/ttnorms/TTNorms-HeavyItalic', 900, italic);

//----------------------------------------------------------------------------------------------------------------------
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Thin', 100);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-ExtraLight', 200);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Light', 300);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Regular', 400);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Medium', 500);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-SemiBold', 600);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Bold', 700);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-ExtraBold', 800);
//@include font-face-woff('Montserrat', '../font/montserrat/Montserrat-Black', 900);
//----------------------------------------------------------------------------------------------------------------------
//@include font-face-woff('Lato', '../font/lato/Lato-Light', 300);
//@include font-face-woff('Lato', '../font/lato/Lato-Regular', 400);
//@include font-face-woff('Lato', '../font/lato/Lato-Bold', 700);
//@include font-face-woff('Lato', '../font/lato/Lato-Black', 900);